// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    baseURL: 'https://api.apcsmusic.com',
  },
  client: {
    baseURL: 'https://api.apcsmusic.com',
  },
};
// export default {
//   api: {
//     baseURL: 'http://localhost:8080',
//   },
//   client: {
//     baseURL: 'http://localhost:8080',
//   },
// };
