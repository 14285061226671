import CLASSICALFESTIVALJKTtwofour1 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour1.jpg";
import CLASSICALFESTIVALJKTtwofour2 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour2.jpg";
import CLASSICALFESTIVALJKTtwofour3 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour3.jpg";
import CLASSICALFESTIVALJKTtwofour4 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour4.jpg";
import CLASSICALFESTIVALJKTtwofour5 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour5.jpg";
import CLASSICALFESTIVALJKTtwofour6 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour6.jpg";
import CLASSICALFESTIVALJKTtwofour7 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour7.jpg";
import CLASSICALFESTIVALJKTtwofour8 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour8.jpg";
import CLASSICALFESTIVALJKTtwofour9 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour9.jpg";
import CLASSICALFESTIVALJKTtwofour10 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour10.jpg";
import CLASSICALFESTIVALJKTtwofour11 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour11.jpg";
import CLASSICALFESTIVALJKTtwofour12 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour12.jpg";
import CLASSICALFESTIVALJKTtwofour13 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour13.jpg";
import CLASSICALFESTIVALJKTtwofour14 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour14.jpg";
import CLASSICALFESTIVALJKTtwofour15 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour15.jpg";
import CLASSICALFESTIVALJKTtwofour16 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour16.jpg";
import CLASSICALFESTIVALJKTtwofour17 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour17.jpg";
import CLASSICALFESTIVALJKTtwofour18 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour18.jpg";
import CLASSICALFESTIVALJKTtwofour19 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour19.jpg";
import CLASSICALFESTIVALJKTtwofour20 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour20.jpg";
import CLASSICALFESTIVALJKTtwofour21 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour21.jpg";
import CLASSICALFESTIVALJKTtwofour22 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour22.jpg";
import CLASSICALFESTIVALJKTtwofour23 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour23.jpg";
import CLASSICALFESTIVALJKTtwofour24 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour24.jpg";
import CLASSICALFESTIVALJKTtwofour25 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour25.jpg";
import CLASSICALFESTIVALJKTtwofour26 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour26.jpg";
import CLASSICALFESTIVALJKTtwofour27 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour27.jpg";
import CLASSICALFESTIVALJKTtwofour28 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour28.jpg";
import CLASSICALFESTIVALJKTtwofour29 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour29.jpg";
import CLASSICALFESTIVALJKTtwofour30 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour30.jpg";
import CLASSICALFESTIVALJKTtwofour31 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour31.jpg";
import CLASSICALFESTIVALJKTtwofour32 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour32.jpg";
import CLASSICALFESTIVALJKTtwofour33 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour33.jpg";
import CLASSICALFESTIVALJKTtwofour34 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour34.jpg";
import CLASSICALFESTIVALJKTtwofour35 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour35.jpg";
import CLASSICALFESTIVALJKTtwofour36 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour36.jpg";
import CLASSICALFESTIVALJKTtwofour37 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour37.jpg";
import CLASSICALFESTIVALJKTtwofour38 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour38.jpg";
import CLASSICALFESTIVALJKTtwofour39 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour39.jpg";
import CLASSICALFESTIVALJKTtwofour40 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour40.jpg";
import CLASSICALFESTIVALJKTtwofour41 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour41.jpg";
import CLASSICALFESTIVALJKTtwofour42 from "../assets/images/galery/CLASSICALFESTIVALJKTtwofour/CLASSICALFESTIVALJKTtwofour42.jpg";


export const FestivalJkt2024 = [
    CLASSICALFESTIVALJKTtwofour10,
    CLASSICALFESTIVALJKTtwofour12,
    CLASSICALFESTIVALJKTtwofour11,
    CLASSICALFESTIVALJKTtwofour13,
    CLASSICALFESTIVALJKTtwofour15,
    CLASSICALFESTIVALJKTtwofour16,
    CLASSICALFESTIVALJKTtwofour17,
    CLASSICALFESTIVALJKTtwofour18,
    CLASSICALFESTIVALJKTtwofour14,
    CLASSICALFESTIVALJKTtwofour19,
    CLASSICALFESTIVALJKTtwofour22,
    CLASSICALFESTIVALJKTtwofour21,
    CLASSICALFESTIVALJKTtwofour1,
    CLASSICALFESTIVALJKTtwofour2,
    CLASSICALFESTIVALJKTtwofour3,
    CLASSICALFESTIVALJKTtwofour4,
    CLASSICALFESTIVALJKTtwofour5,
    CLASSICALFESTIVALJKTtwofour6,
    CLASSICALFESTIVALJKTtwofour7,
    CLASSICALFESTIVALJKTtwofour8,
    CLASSICALFESTIVALJKTtwofour9,
    CLASSICALFESTIVALJKTtwofour23,
    CLASSICALFESTIVALJKTtwofour24,
    CLASSICALFESTIVALJKTtwofour25,
    CLASSICALFESTIVALJKTtwofour26,
    CLASSICALFESTIVALJKTtwofour27,
    CLASSICALFESTIVALJKTtwofour28,
    CLASSICALFESTIVALJKTtwofour29,
    CLASSICALFESTIVALJKTtwofour30,
    CLASSICALFESTIVALJKTtwofour31,
    CLASSICALFESTIVALJKTtwofour32,
    CLASSICALFESTIVALJKTtwofour33,
    CLASSICALFESTIVALJKTtwofour34,
    CLASSICALFESTIVALJKTtwofour35,
    CLASSICALFESTIVALJKTtwofour36,
    // CLASSICALFESTIVALJKTtwofour37,
    CLASSICALFESTIVALJKTtwofour38,
    CLASSICALFESTIVALJKTtwofour39,
    CLASSICALFESTIVALJKTtwofour40,
    CLASSICALFESTIVALJKTtwofour41,
    CLASSICALFESTIVALJKTtwofour42,
];